import React from "react";
import Button from "../styled/button";
import { SelectCreatable } from "../../components/styled/select";
import { SleeveConditions, ListingOptionName } from "./listingSelect";
import { useLazyQuery } from "@apollo/client";
import { Item, ListingOption } from "../../__generated__/graphql";
import { GET_CONFIG_METADATA_OPTIONS } from "../../graphql/queries/config";
import { ItemEditable } from "../items/edit.release";
import { useTranslation } from "react-i18next";

export function Options({
  options,
  item,
  overZone,
  onCreate,
  onChange,
  onAdd,
  deleteOption
}: {
  deleteOption: any;
  onAdd: any;
  onCreate: any;
  onChange: any;
  overZone?: boolean;
  options: ListingOption[];
  item: Item | ItemEditable;
}) {
  const [getData, { data }] = useLazyQuery(GET_CONFIG_METADATA_OPTIONS, { fetchPolicy: "no-cache" });

  const isRelease = item.type === "ReleaseItem";
  const names = isRelease ? ListingOptionName : data?.configMetadata?.options?.names || [];
  const values = isRelease ? SleeveConditions : data?.configMetadata?.options?.values || [];
  const { t } = useTranslation();
  return (
    <>
      <div className="options">
        {options.map((o, index) => (
          <div key={index} className="option">
            <SelectCreatable
              label={t("Name")}
              onMenuOpen={() => (!isRelease ? getData() : null)}
              variant={overZone && "overZone"}
              onCreateOption={(v: any) => onCreate(v, "name", index)}
              onChange={(o: any, e: any) => onChange(o, e, index)}
              value={o.name ? { value: o.name, label: o.name } : null}
              options={names.map(v => ({ label: v, value: v }))}
              isClearable={false}
              name="name"
              placeholder={t("Name") + "..."}
            />
            <SelectCreatable
              label={t("Value")}
              onMenuOpen={() => (!isRelease ? getData() : null)}
              variant={overZone && "overZone"}
              onCreateOption={(v: any) => onCreate(v, "value", index)}
              onChange={(o: any, e: any) => onChange(o, e, index)}
              value={o.value ? { value: o.value, label: o.value } : null}
              options={values.map(v => ({ label: v, value: v }))}
              isClearable={false}
              name="value"
              placeholder={t("Value") + "..."}
            />
            <Button variant={`secondary${overZone ? "OverZone" : ""}`} type="button" onClick={() => deleteOption(index)}>
              ×
            </Button>
          </div>
        ))}
        <div>
          <Button fullWidth variant={`secondary${overZone ? "OverZone" : ""}`} type="button" onClick={onAdd}>
            {t("Add an option")}
          </Button>
        </div>
      </div>
    </>
  );
}
