import React from "react";
import { GlobalStore } from "../../stores/global";
import { Helmet } from "react-helmet";
import moment from "moment";
import { getConfigProperty } from "../../utils";
import { Config } from "../../__generated__/graphql";

function truncate(string: string, num?: number) {
  if (!num) num = 150;
  string = string.replace(/"/, " ");
  if (string.length > num) {
    return string.substring(0, num - 3) + " ...";
  } else return string;
}

export default function Meta({
  path,
  title: titleProp,
  updated,
  image,
  description: descriptionProp
}: {
  updated?: string;
  image?: string | null;
  path?: string;
  title?: string;
  description?: string | null;
}) {
  const config = GlobalStore.useState(c => c.config) as Config;
  const canonical = path !== undefined ? "https://" + config.domain + path : null;
  const title = titleProp
    ? `${titleProp} - ${getConfigProperty(config, "information", "shopName")}`
    : getConfigProperty(config, "information", "shopName");
  const defaultImage = getConfigProperty(config, "designs", "logoImage");
  const description = descriptionProp || title;

  return (
    <Helmet>
      <title>{title}</title>
      {canonical ? <link rel="canonical" href={canonical} /> : null}
      {canonical ? <meta property="og:url" content={canonical}></meta> : null}
      <meta name="keywords" content={title.replace(/[^a-zA-Z ]/g, "").replace(/ /g, ",")} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      {updated ? <meta property="og:updated_time" content={String(moment(updated).unix())} /> : null}
      {description ? <meta name="description" content={description} /> : null}
      {description ? <meta property="og:description" content={truncate(description, 200)} /> : null}
      <meta property="og:image" itemProp="image" content={image || defaultImage} />
    </Helmet>
  );
}
