import React, { useEffect } from "react";
import Zone from "../../components/styled/zone";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { GlobalStore } from "../../stores/global";
import { FiCheck, FiMaximize } from "react-icons/fi";
import { Reduce } from "./icons";
import { useQuery } from "@apollo/client";
import { GET_CONFIG_ONBOARDING } from "../../graphql/queries/config";
import { useTranslation } from "react-i18next";

export default function Tutorial() {
  const { tutorial } = GlobalStore.useState(s => s);
  const { data } = useQuery(GET_CONFIG_ONBOARDING);
  const { t } = useTranslation();

  const steps = data?.configOnboarding?.steps || [];

  const hidden = localStorage.getItem("tutorial-hidden") === "true";

  const toggleMinimize = () => {
    localStorage.setItem("tutorial-minimized", tutorial.minimized ? "false" : "true");
    GlobalStore.update(s => {
      s.tutorial.minimized = !s.tutorial.minimized;
    });
  };

  const handleClose = () => {
    localStorage.setItem("tutorial-hidden", "true");
    GlobalStore.update(s => {
      s.tutorial.hidden = true;
    });
  };

  const todos = steps && steps.filter(s => !s.completed);

  useEffect(() => {
    if (todos.length === 0) handleClose();
  }, [todos.length]);

  useEffect(() => {
    if (!hidden) {
      GlobalStore.update(s => {
        s.tutorial.hidden = false;
      });
    }
  }, [hidden]);

  if (tutorial === undefined) return null;

  return (
    tutorial &&
    !tutorial.hidden && (
      <div id="tutorial" className={"steps"}>
        <div className="top">
          <div className="state"></div>
          <div className="text">
            <button type="button" onClick={toggleMinimize}>
              {t("Setup your shop")} {steps.length - todos.length}/{steps.length}
            </button>
          </div>
          <div className="icons">
            <button onClick={toggleMinimize}>{tutorial.minimized ? <FiMaximize /> : <Reduce />}</button>
            <button onClick={handleClose}>
              <FaTimes />
            </button>
          </div>
        </div>

        <Zone className={`steps ${tutorial.minimized ? "minimized" : ""}`}>
          {steps.map((step, i) => (
            <Link to={step.path || ""} key={i} className="step">
              <div className={`step ${step.completed ? "completed" : ""}`}>
                <div className="state">{step.completed ? <FiCheck /> : i + 1}</div>
                <div className="text">
                  <p>{step.title}</p>
                </div>
              </div>
            </Link>
          ))}
        </Zone>
      </div>
    )
  );
}
