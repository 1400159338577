import React, { useState, useRef } from "react";
import { Button } from "../../../componentsV2/Button";
import Checkbox from "../../../components/styled/checkbox";
import Input from "../../../components/styled/input";
import Loader from "../../../components/common/loader";
import { GlobalStore } from "../../../stores/global";
import clone from "clone";
import { POST_ORDER_MARK_AS_SHIPPED } from "../../../graphql/queries/order";
import { useMutation } from "@apollo/client";
import Modal, { PropsToForward } from "../../modal";
import Styled from "styled-components";
import Color from "color-js";
import { getConfigProperty } from "../../../utils";
import { useTranslation } from "react-i18next";
import { Config, LineItem, Order } from "../../../__generated__/graphql";
import { EThemes } from "../../../types/globals";
import { ModalHeaderContainer } from "../../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../../componentsV2/Typography";
import { Link } from "react-router-dom";

const PackingList = ({ order, refetch }: { order: any; refetch: any }) => {
  const { config, theme, addNotification, isBelowIpad: isMobile } = GlobalStore.useState(c => c);
  const itemsToWorkWith = clone(order.items.map((i: any) => ({ ...i, packed: 0 }))).filter((i: any) => !i.unavailable);
  const initialStep = itemsToWorkWith.length ? "packing" : "markAsShipped";
  const [step, setStep] = useState(initialStep);
  const modalRef: any = useRef<PropsToForward>();
  const isPickup = order.shipping && order.shipping.shopCollection;

  const { t } = useTranslation();

  const modalStyle = isMobile ? { minWidth: "95vw", padding: "0px" } : { width: step === "packing" ? "50vw" : "30vw", padding: "0px" };
  return (
    <div className="proceedToDelivery">
      <Modal ref={modalRef} style={modalStyle} onClose={() => setStep(initialStep)}>
        <div id="deliveryModal">
          {step === "packing" ? (
            <Packing
              theme={theme as EThemes}
              isMobile={isMobile}
              order={order}
              config={config as Config}
              skip={() => setStep("markAsShipped")}
            />
          ) : step === "markAsShipped" ? (
            <MarkAsShipped
              refetch={refetch}
              addNotification={addNotification}
              order={order}
              isPickup={isPickup}
              setStep={setStep}
              skip={() => setStep(initialStep)}
            />
          ) : null}
        </div>
      </Modal>
      <Button variant="primary" fullWidth type="button" onClick={() => modalRef.current.open()}>
        {t("Proceed to delivery")}
      </Button>
    </div>
  );
};

const buttonColor = "#FF7D4C";
const Info = Styled.div`
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  p {
    margin: 0;
  }
  color: ${props => (props.theme === "dark" ? Color("#fff").setAlpha(0.5) : Color(buttonColor)).toString()};
  background-color: ${Color(buttonColor).setAlpha(0.2).toString()};
`;

const Packing = ({
  order,
  config,
  skip,
  isMobile,
  theme
}: {
  order: Order;
  config: Config;
  skip: any;
  isMobile: boolean;
  theme: EThemes;
}) => {
  const { t } = useTranslation();
  const itemsToWorkWith = clone(order.items.map((i: any) => ({ ...i, packed: 0 }))).filter((i: any) => !i.unavailable);
  const totalItemsToPack = itemsToWorkWith.reduce((acc: number, item: any) => item.quantity + acc, 0);
  const [toPack, setToPack] = useState<(LineItem & { packed: number })[]>(itemsToWorkWith);
  const leftToPack = toPack.reduce((acc: number, i: any) => acc + (i.quantity - i.packed), 0);
  const [index, setIndex] = useState(0);
  // const [sortByLocation, setSortByLocation] = useState(false);

  const totalItemsPacked = toPack.reduce((acc: number, item: any) => item.packed + acc, 0);

  const handleAddToParcel = (idx: number) => {
    const item = toPack[index];
    toPack[idx].packed = item.quantity;
    setToPack(clone(toPack));
    if (idx === toPack.length - 1) {
      skip();
    } else setIndex(index + 1);
  };

  // const handleSortByLocation = (state: boolean) => {
  //   setSortByLocation(state);
  //   setToPack(
  //     state
  //       ? itemsToWorkWith.sort((a: any, b: any) =>
  //           (a.listing.location || "").toLowerCase() > (b.listing.location || "").toLowerCase() ? 1 : -1
  //         )
  //       : itemsToWorkWith
  //   );
  //   setIndex(0);
  // };

  const handlePrevious = () => {
    setIndex(index - 1);
  };

  const reset = () => {
    setToPack(clone(order.items.map((i: any) => ({ ...i, packed: 0 }))));
    setIndex(0);
  };

  const item = toPack[index];
  const imageSrc = item?.item?.thumbnail || getConfigProperty(config, "designs", "missingImageUri") || "/missing-primary.png";

  console.log(item.listing);

  if (!item) return null;
  if (isMobile)
    return (
      <div className="packing mobile">
        <div className="header">
          <p className="left">
            {t("Left to pack")}: {leftToPack}/{totalItemsToPack}
          </p>
          <div className="right">
            <Button type="button" variant="secondary" disabled={totalItemsPacked === 0} onClick={reset}>
              {t("Start over")}
            </Button>
            <Button type="button" variant={"secondary"} onClick={skip}>
              {t("Skip")}
            </Button>
          </div>
        </div>
        <div className="content">
          <div className="items">
            <div className="item" key={item._id}>
              <img id="mainImage" alt={item.item.description || ""} src={imageSrc} />
              <div className="details">
                <Link to={item.item.path || ""}>
                  <h2 className="description">{item.item.description}</h2>
                </Link>
              </div>
            </div>
            <div className="details">
              {item.listing.options.map((o: any) => (
                <div key={o.name} className="option listingEntry">
                  <p className="title">{o.name}</p>
                  <p className="value">{o.value}</p>
                </div>
              ))}
              {item.listing.location ? (
                <div className="listingEntry">
                  <p className="title">{t("Location")}</p>
                  <p className="value">{item.listing.location}</p>
                </div>
              ) : null}
              {item.listing.supplierCode ? (
                <div className="listingEntry">
                  <p className="title">{t("Supplier")}</p>
                  <p className="value">{item.listing.supplierCode}</p>
                </div>
              ) : null}
              {item.listing.privateComments ? (
                <div className="listingEntry">
                  <p className="title">{t("Private comments")}</p>
                  <p className="value">{item.listing.privateComments}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="info">
          <p>
            {t("Quantity")} {item.quantity}
          </p>
        </div>
        <div className="footer">
          <span>
            <Button type="button" disabled={index <= 0} variant="secondary" onClick={() => handlePrevious()}>
              {t("Previous")}
            </Button>
          </span>
          {/* <Button className="sort" type="button" onClick={(e: unknown) => handleSortByLocation(!sortByLocation)} variant="noStyle">
            {sortByLocation ? t("Sorted by location") : t("Sorted by default")}
          </Button> */}
          <span>
            <div className="right">
              <Button type="button" variant="primary" onClick={() => handleAddToParcel(index)}>
                {index === toPack.length - 1 ? t("Proceed to delivery") : t("Next item")}
              </Button>
            </div>
          </span>
        </div>
      </div>
    );

  return (
    <div className="packing">
      <ModalHeaderContainer className="header">
        <div className="left">
          <Typography variant="pageTitle" tag="h2">
            {t("Packing list")}
          </Typography>
        </div>
        <p className="center">
          {t("Left to pack")}: {leftToPack}/{totalItemsToPack}
        </p>
        <div style={{ display: "flex", gap: "var(--gutter)", justifyContent: "space-between" }}>
          <Button type="button" variant="secondary" disabled={totalItemsPacked === 0} onClick={reset}>
            {t("Start over")}
          </Button>
          <Button type="button" variant={"secondary"} onClick={skip}>
            {t("Skip packing")}
          </Button>
        </div>
      </ModalHeaderContainer>
      <div className="content">
        <div className="items">
          <div className="item" key={item._id}>
            <img id="mainImage" alt={item.item.description || ""} src={imageSrc} />
            <div className="details">
              <Link to={item.item.path || ""}>
                <h2 className="description">{item.item.description}</h2>
              </Link>
              {item.listing.options.map((o: any) => (
                <div key={o.name} className="option listingEntry">
                  <p className="title">{o.name}</p>
                  <p className="value">{o.value}</p>
                </div>
              ))}
              {item.listing.location ? (
                <div className="listingEntry">
                  <p className="title">{t("Location")}</p>
                  <p className="value">{item.listing.location}</p>
                </div>
              ) : null}
              {item.listing.supplierCode ? (
                <div className="listingEntry">
                  <p className="title">{t("Supplier")}</p>
                  <p className="value">{item.listing.supplierCode}</p>
                </div>
              ) : null}
              {item.listing.privateComments ? (
                <div className="listingEntry">
                  <p className="title">{"Private comments"}</p>
                  <p className="value">{item.listing.privateComments}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <span>
          <Button type="button" disabled={index <= 0} variant="secondary" onClick={() => handlePrevious()}>
            {t("Previous")}
          </Button>
          {/* <Button type="button" onClick={(e: unknown) => handleSortByLocation(!sortByLocation)} variant="noStyle">
            {sortByLocation ? t("Sorted by location") : t("Sorted by default")}
          </Button> */}
        </span>
        <Info className="info" theme={theme}>
          <p>
            {t("Quantity")} {item.quantity}
          </p>
        </Info>
        <span>
          <div className="right">
            <Button type="button" variant="primary" onClick={() => handleAddToParcel(index)}>
              {index === toPack.length - 1 ? t("Proceed") : t("Next item")}
            </Button>
          </div>
        </span>
      </div>
    </div>
  );
};

const MarkAsShipped = ({
  addNotification,
  order,
  isPickup,
  skip,
  refetch,
  setStep
}: {
  addNotification: any;
  order: any;
  isPickup: boolean;
  skip: any;
  refetch: any;
  setStep: any;
}) => {
  const [shouldSendConfirmationEmail, setShouldSendConfirmationEmail] = useState(false);
  const [markAsShipped] = useMutation(POST_ORDER_MARK_AS_SHIPPED);
  const [isLoading, setIsLoading] = useState(false);
  const handleEmailConfirmationChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setShouldSendConfirmationEmail(e.target.checked);
  };
  const { t } = useTranslation();

  const handleMarkAsShipped = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const target = {
      email: e.target.email?.value,
      trackingLink: e.target.trackingLink?.value,
      emailConfirmation: e.target.emailConfirmation?.checked
    } as any;
    try {
      await markAsShipped({
        variables: {
          orderRef: order._id,
          trackingLink: target.trackingLink,
          email: target.emailConfirmation ? target.email : null
        }
      } as any);
      skip();
      addNotification({ ok: 1, message: "Marked as shipped" });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    } finally {
      setIsLoading(false);
      refetch();
    }
  };

  return (
    <div className="markAsShipped">
      <ModalHeaderContainer>
        <Typography variant="pageTitle" tag="h2">
          {isPickup ? t("Mark as collected") : t("Mark as shipped")}
        </Typography>
        <button type="button" className="reset" onClick={skip}>
          <i className="cg-icon-burger-close" />
        </button>
      </ModalHeaderContainer>
      <form onSubmit={handleMarkAsShipped}>
        {!isPickup ? (
          <Input
            label={t("Tracking link")}
            variant="overZone"
            pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
            name="trackingLink"
            type="text"
            autoComplete="off"
            title="A url must be entered"
            placeholder={t("Enter a web URL")}
          />
        ) : null}
        {order.origin !== "Discogs" ? (
          <>
            <Checkbox
              label={t("Send a confirmation email")}
              checked={shouldSendConfirmationEmail}
              onChange={handleEmailConfirmationChange}
              name="emailConfirmation"
            />
            {shouldSendConfirmationEmail && order.origin !== "Discogs" ? (
              <Input
                label={t("Recipient email address")}
                variant="overZone"
                required
                autoComplete="off"
                placeholder={t("Recipient email address")}
                type="email"
                name="email"
                defaultValue={order.buyer ? order.buyer.email : ""}
              />
            ) : null}
          </>
        ) : null}
        <div className="footer">
          <Button variant="secondary" type="button" onClick={() => setStep("packing")}>
            {t("Back to packing")}
          </Button>
          <Button variant="primary" disabled={isLoading} type="submit">
            {isLoading ? <Loader /> : isPickup ? t("Mark as collected") : t("Mark as shipped")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PackingList;
