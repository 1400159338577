import React, { useState, useRef } from "react";
import Button from "../../styled/button";
import { Button as ButtonV2 } from "../../../componentsV2/Button";
import Price from "../../common/price";
import Loader from "../../common/loader";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { restartMembership } from "../../../@services/configService";
import Modal from "../../modal";
import { GlobalStore } from "../../../stores/global";
import { PaymentMethods, BillingPlan } from "./billing";
import { ConfigStripUserPaymentMethod, ConfigStripeUser } from "../../../__generated__/graphql";
import { AddNotification } from "../../../types/globals";
import { useTranslation } from "react-i18next";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE as string);

const BlockedAccount = ({ customer, hasOutstandingInvoices }: { customer: ConfigStripeUser; hasOutstandingInvoices: boolean }) => {
  const { config, addNotification, configReload } = GlobalStore.useState(c => c);
  const { t } = useTranslation();

  const handleMembershipStarted = async () => {
    configReload();
  };

  return (
    <section id="blockedAccount">
      <h2>
        {t("Your backoffice access was temporarily deactivated")}.
        <br />
        {config?.status?.reason}
      </h2>
      <p>
        {t("To reactivate your account please add a payment method below and make payments for all outstanding invoices")}.
        <br />
        {t("Your Eshop access will remain effective for a month after your account becomes inactive")}.
        <br /> {t("For more information contact us at")} <a href="mailto:support@common-ground.io">{"support@common-ground.io"}</a>
      </p>
      {!hasOutstandingInvoices ? (
        <Elements stripe={stripePromise}>
          <RestartMembership
            addNotification={addNotification}
            paymentMethods={customer && customer.paymentMethods}
            customer={customer}
            handleMembershipStarted={handleMembershipStarted}
          />
        </Elements>
      ) : null}
    </section>
  );
};

const RestartMembership = ({
  handleMembershipStarted,
  paymentMethods,
  customer,
  addNotification
}: {
  handleMembershipStarted: any;
  paymentMethods?: ConfigStripUserPaymentMethod[];
  customer: ConfigStripeUser;
  addNotification: AddNotification;
}) => {
  const modalRef = useRef<any>();
  const { t } = useTranslation();

  const plans: BillingPlan[] = [
    {
      recurrence: "monthly",
      title: t("Individuals"),
      trial: { available: true, days: 14 },
      price: { value: 50 },
      seats: 1,
      description: t("For sole traders and sellers running their business solo")
    },
    {
      recurrence: "monthly",
      title: t("Standard"),
      price: { value: 135 },
      seats: 3,
      description: t("For small retailers, online vendors and organizations with up to 3 employees")
    },
    {
      recurrence: "monthly",
      title: t("Extended"),
      price: { value: 270 },
      seats: 6,
      description: t("For sizable businesses with up to 6 employees")
    }
  ];

  const [plan, setPlan] = useState<BillingPlan>({ ...plans[0] });
  const [isCharging, setIsCharging] = useState(false);
  const stripe = useStripe();

  const handleSelectPlan = (e: any) => {
    const plan = plans.find(p => p.title === e.target.value);
    if (plan) setPlan(plan);
  };

  const handleSubmitPayment = async (e: any) => {
    e.preventDefault();
    if (!e.target.plan.value) return;
    setIsCharging(true);
    try {
      const { data } = await restartMembership({ plan: e.target.plan.value });
      if (data.error && data.error === "authentication_required") {
        const intent = data.intent;
        const results = await stripe?.confirmCardPayment(intent.client_secret, {
          payment_method: intent.last_payment_error.payment_method.id
        });
        if (results?.error) addNotification({ ok: 0, message: results.error.message || "" });
        else if (results?.paymentIntent.status === "succeeded") {
          addNotification({ ok: 1, message: "Invoice was paid successfully" });
          window.location.reload();
        }
      } else if (data.ok) addNotification(data);
      await handleMembershipStarted();
      modalRef.current.close();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data });
    } finally {
      setIsCharging(false);
    }
  };

  return (
    <div className="restartMembership" style={{ display: "flex", justifyContent: "center" }}>
      <Modal style={{}} ref={modalRef}>
        <div id="restartMembershipModalContent">
          {!paymentMethods || !paymentMethods.length ? (
            <PaymentMethods addNotification={addNotification} customer={customer} />
          ) : (
            <form onSubmit={handleSubmitPayment}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2>{t("Select your preferred plan below")}</h2>
                <Button variant="secondary" onClick={() => modalRef.current.close()}>
                  {t("Cancel")}
                </Button>
              </div>
              {plans.map(p => (
                <label key={p.title}>
                  <input
                    type="radio"
                    className="plan"
                    name="plan"
                    checked={p.title === plan.title}
                    onChange={handleSelectPlan}
                    value={p.title}
                  />
                  {p.title} - <Price value={p.price.value} currencySymbol="€" /> / {t("month")}
                </label>
              ))}
              <div className="total">
                {t("Payable total for this invoice is")} <Price value={plan.price.value} currencySymbol="€" />
              </div>
              <ButtonV2 type="submit" variant="primary" disabled={!paymentMethods || paymentMethods.length === 0 || isCharging}>
                {isCharging ? <Loader /> : t("Submit payment")}
              </ButtonV2>
            </form>
          )}
        </div>
      </Modal>
      <ButtonV2 variant="primary" type="button" onClick={() => modalRef.current.open()}>
        {t("Subscribe to a plan")}
      </ButtonV2>
    </div>
  );
};

export default BlockedAccount;
