import React, { useEffect } from "react";
import { Link, Switch, Route, useHistory } from "react-router-dom";

import Information from "./tabs/information";
import Backoffice from "./tabs/backoffice";
import Legal from "./tabs/legal";
import Taxes from "./tabs/taxes";
import URI from "urijs";
import { GlobalStore } from "../../stores/global";
import { useMutation } from "@apollo/client";
import { POST_CONFIG_MODULE_UPDATE } from "../../graphql/queries/config";
import Billing, { AdminList } from "./tabs/billing";
import Summary from "./tabs/summary";
import Referrals from "./tabs/includes/referrals";
import InventoryPreferences from "./tabs/includes/inventoryPreferences";
import PointOfSale from "./tabs/includes/pointOfSale";
import Templates from "../templates/templates";
import { Config, Session } from "../../__generated__/graphql";
import { useTranslation } from "react-i18next";

export default function Preferences() {
  const history = useHistory();
  const { config, addNotification, session, logout, configReload, isMobile, helpPanel } = GlobalStore.useState(c => c);
  const [updateModule] = useMutation(POST_CONFIG_MODULE_UPDATE);
  const { t } = useTranslation();

  const entries = [
    { title: t("Account information"), id: "information" },
    { title: t("Subscription & Invoices"), id: "billing" },
    { title: t("Team"), id: "team" },
    { title: t("Referrals & Credits"), id: "referrals" },
    { title: "", id: "separator" },
    { title: t("External services"), id: "external-services" },
    { title: t("Point of sale"), id: "pos" },
    { title: t("Templates"), id: "templates" },
    { title: t("Legal & Invoicing"), id: "legal" },
    { title: t("Tax settings"), id: "taxes" },
    { title: t("Inventory"), id: "inventory" }
  ];

  let currentTabIndex: null | number = null;
  const segments = new URI(window.location.href).segment();
  if (segments.length === 2) currentTabIndex = entries.findIndex(e => e.id === segments[1]);

  useEffect(() => {
    if (currentTabIndex === null) history.replace("/preferences/information");
  }, [currentTabIndex]);

  const updateModuleData = async (state: any) => {
    try {
      await updateModule({ variables: { id: state.id, data: state.data } });
      addNotification({ ok: 1, message: "Preferences updated" });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  document.title = "Preferences";

  useEffect(() => {
    configReload();
    GlobalStore.update(s => {
      s.menuIsCollapsed = true;
    });
    return () => {
      GlobalStore.update(s => {
        s.menuIsCollapsed = false;
      });
    };
  }, []);

  return (
    <div id="preferences">
      <div className="tabs">
        <div className="content">
          {entries.map((e, idx) => (
            <React.Fragment key={e.id}>
              {e.id !== "separator" ? (
                <div className={`linkEntry ${currentTabIndex === idx ? "active" : ""}`}>
                  <Link to={`/preferences/${entries[idx].id}`}>{e.title}</Link>
                </div>
              ) : (
                <hr style={{ visibility: "hidden" }} />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="content">
        <Switch>
          <Route exact path={["/preferences", "/preferences/information"]}>
            <Summary config={config as Config} />
            <Information
              config={config as Config}
              updateModule={updateModuleData}
              addNotification={addNotification}
              module={config?.modules.find((m: any) => m.id === "information")}
            />
          </Route>
          <Route exact path="/preferences/billing">
            <Billing />
          </Route>
          <Route exact path="/preferences/team">
            <AdminList
              helpPanel={helpPanel}
              isMobile={isMobile}
              config={config as Config}
              addNotification={addNotification}
              session={session as Session}
            />
          </Route>
          <Route exact path="/preferences/legal">
            <Legal
              updateModule={updateModuleData}
              ga={config?.modules.find((m: any) => m.id === "googleAnalytics")}
              templates={config?.modules.find((m: any) => m.id === "templates")}
              information={config?.modules.find((m: any) => m.id === "information")}
              shipping={config?.modules.find((m: any) => m.id === "shipping")}
              facebook={config?.modules.find((m: any) => m.id === "facebook")}
            />
          </Route>
          <Route exact path="/preferences/external-services">
            <Backoffice />
          </Route>
          <Route exact path="/preferences/taxes">
            <Taxes />
          </Route>
          <Route exact path="/preferences/referrals">
            <Referrals addNotification={addNotification} helpPanel={helpPanel} />
          </Route>
          <Route exact path={["/preferences/advanced", "/preferences/inventory"]}>
            <InventoryPreferences config={config as Config} helpPanel={helpPanel} logout={logout} addNotification={addNotification} t={t} />
          </Route>
          <Route exact path="/preferences/pos">
            <PointOfSale addNotification={addNotification} session={session} config={config as Config} />
          </Route>
          <Route exact path="/preferences/templates">
            <Templates />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
